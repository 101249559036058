html,
body {
  background-color: #ffffff;
  font-family: 'Archivo', sans-serif;
}

@font-face {
  font-family: "SomeType Mono";
  src: url("/fonts/SomeType-Mono/SometypeMono-Regular.ttf");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
